import Vue from 'vue';
import Home from './Home.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import 'jquery';
import { loganLog } from "@/utils/log";
import Totast from '@/components/totast/index.js';
Vue.prototype.$totast = Totast;
import './utils/directives';
import { directive as onClickaway } from 'vue-clickaway';
Vue.directive('on-clickaway', onClickaway);
import { getAcceptLanguage } from '@/utils/auth';
import { imEnv } from '@/config/index';
import { LANG_ZH } from '@/constant/index';
import zh from "@/assets/lang/zh.js";
import en from "@/assets/lang/en.js";
import enElLocale from 'element-ui/lib/locale/lang/en';
import zhElLocale from 'element-ui/lib/locale/lang/zh-CN';
var langVal = getAcceptLanguage();
Vue.use(ElementUI, { locale: langVal === LANG_ZH ? zhElLocale : enElLocale });
Vue.use(VueI18n);
var i18n = new VueI18n({
    locale: langVal,
    silentFallbackWarn: true,
    fallbackLocale: 'zh-CN',
    messages: {
        'en-US': en,
        'zh-CN': zh
    }
});
import '@/assets/css/animate/animate.min.css';
import '@/assets/css/vue.plugin/vue.plugin.scss';
import '@/assets/css/iconFont/iconfont.css';
import '@/assets/css/iconFont100/iconfont.css';
import configs from '@/lib/core/configs.js';
import tools from '@/lib/core/tools.js';
import timer from '@/lib/core/timer.js';
import vuePlugins from '@/lib/vue.plugin/Vue.Plugin.Install.js';
import './permission'; // permission control
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
import YB_MEET_SDK from './YBMeetSDK';
// Vue.prototype.$i100MeetSDK = new I100MeetSDK({
//   imEnv
// })
import NoSleep from 'nosleep.js';
console.log('current env: ', imEnv);
loganLog('程序开始-v1.10.28');
loganLog("current env: " + imEnv);
loganLog("current userAgent: " + navigator.userAgent);
var yb_instance = new YB_MEET_SDK({
    appId: '',
    appSecret: '',
    mode: imEnv
});
sessionStorage.setItem('doc_env', imEnv);
Vue.prototype.$noSleep = NoSleep;
Vue.prototype.$i100MeetSDK = new Proxy(yb_instance, {
    get: function (target, key) {
        if (key === 'i100Settings') {
            return target.settings;
        }
        if (key === 'i100Authentication') {
            return target.auth;
        }
        if (key === 'i100MeetintManagement') {
            return target.meetingManagement;
        }
        if (key === 'i100MeetingControl') {
            return target.rtc;
        }
        if (key === 'i100IM') {
            return target.im;
        }
        return target[key];
    }
});
// console.log('直接访问',Vue.prototype.$i100MeetSDK)
// // this. = new Authentication()
//     this.i100MeetintManagement = new MeetintManagement()
//     this.i100MeetingControl = new MeetingControl(roomClient)
//     this.i100Settings = new Settings(roomClient)
//     this.i100IM = new IM({
//       env: imEnv
//     })
// console.log('ybmeetInstance', Vue.prototype.$i100MeetSDK.i100Settings)
// alias 相关业务
// Vue.prototype.$i100MeetSDK.i100Authentication = yb_instance.auth
// Vue.prototype.$i100MeetSDK.i100MeetintManagement = yb_instance.meetingManagement
// Vue.prototype.$i100MeetSDK.i100MeetingControl = yb_instance.rtc
// Vue.prototype.$i100MeetSDK.i100Settings = yb_instance.meetSetting
// Vue.prototype.$i100MeetSDK.i100IM = yb_instance.im
Vue.config.productionTip = false; // 以阻止 vue 在启动时生成生产提示
// 组件部分
Vue.use(vuePlugins); // 使用自定义组件
// 业务核心部分
Vue.use(configs); // 装配项目配置文件
Vue.use(tools); // 装配项目工具文件
Vue.use(timer); // 装配计时器功能
import './styles/element-variables.scss';
Vue.prototype.$sentry = {
    captureException: function () { }
};
// sensors.init({
//   server_url: 'https://dotting.cmalive.cn/25c84829592de9661a4db77d4c49cbd9/wow.gif',
//   is_track_single_page:true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
//   use_client_time:true, 
//   send_type:'beacon',
//   heatmap: {
//      //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
//      clickmap:'not_collect',
//      //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
//      scroll_notice_map:'not_collect'
//   }
// });
// sensors.registerPage({
// 	platform: "web"
// });
// sensors.quick('autoTrack'); //用于采集 $pageview 事件。
// Vue.prototype.$sensors = sensors
window.vm = new Vue({
    router: router,
    store: store,
    i18n: i18n,
    render: function (h) { return h(Home); }
}).$mount('#app');
