<template>
  <div class="join-container" :class="bgClassType === 1 ? 'bg-default' : bgClassType === 2 ? 'bg-omnitest' : ''">
    <div id="videoconference_page">
      <div class="premeeting-screen" id="lobby-screen">
        <Header class="z-index"  v-if="!joinParamData || joinParamData && joinParamData.businessType != 10008" ></Header>
        <Back @handleBack="handleBack" v-if="isShowBackLink && !joinParamData || joinParamData && ![10007,10008].includes(joinParamData.businessType)" class="z-index" :class="[isShow ? 'back_active' : '']"></Back>
        <div id="preview" v-show="isShowVideo">
          <video autoplay class="media-reset-flag" id="joinVideo" playsinline></video>
        </div>
        <div class="content">
          <div class="box" :class="{'gray': isShow}">
            <h5 class="title" :class="[isShow ? 'active' : '']">{{ joinParamData.businessType == 10008 ?titleInfo : $t('home.joinMeeting')}}</h5>
            <div class='time_style' v-if="joinParamData && [10008].includes(joinParamData.businessType)">{{timeinfo}}</div>
            <div class='line_style' v-if="joinParamData && [10008].includes(joinParamData.businessType)"></div>

            <div class="prejoin-input-area-container">
              <div class="prejoin-input-area">
                <el-input
                  id="join_number_input_headless"
                  :class="uhfList.length > 1?'paddingleft':''"
                  class="commonElInput"
                  v-model="conferenceNo"
                  @input="onInput"
                  :placeholder="$t('login.numberTips')"
                  clearable
                  v-if="!joinParamData || joinParamData && ![10007,10008].includes(joinParamData.businessType)"
                ></el-input>

                <el-select
                  v-if="uhfList.length > 1 && (!joinParamData || joinParamData && ![10007,10008].includes(joinParamData.businessType))"
                  class="commonElSelect"
                    v-model="conferenceNo" 
                  :placeholder="$t('login.numberTips')"
                  popper-class="commonSelectBox"
                >
                  <el-option
                    v-for="(item, index)  in uhfList"
                    :value="item.id" :key="index"
                  >
                    <span :title="item.name">{{ strMiddleEllipsis(item.name, 16, 6, 6) }}</span>
                    <span>{{ item.id }}</span>
                  </el-option>
                  <div class="clearUhfList" @click="clearUhfList">清除数据</div>
                </el-select>

                <el-input
                  id="join_name_input_headless"
                  class="commonElInput"
                  v-model="userName" 
                  :disabled="joinParamData.businessType == 10007"
                  v-if="!joinParamData || joinParamData && ![10008].includes(joinParamData.businessType)"
                  :placeholder="$t('login.tipsName')"
                  clearable
                ></el-input>

                <div style="margin-top: 24px">
                  <el-checkbox id="join_microphone_checkbox_headless" :class="video ? 'labelColor': 'no_video'" v-model="myCheckbox">{{$t('login.openMicrophone')}}</el-checkbox>
                </div>
                <div style="margin-top: 6px">
                  <el-checkbox id="join_speaker_checkbox_headless" :class="video ? 'labelColor': 'no_video'" v-model="loudspeaker">{{$t('login.openSpeaker')}}</el-checkbox>
                </div>
                <div style="margin-top: 6px">
                  <el-checkbox id="join_camera_checkbox_headless" :class="video ? 'labelColor': 'no_video'" v-model="myCamera" @change="changeDevice">{{$t('login.openCamera')}}</el-checkbox>
                </div>
                <!-- <div style="margin-top: 6px"> //腾讯美颜到期 暂不续费
                  <el-checkbox id="join_beauty_checkbox_headless" :class="beauty ? 'labelColor': 'no_video'"  @change="beautChecked" v-model="beauty">开启美颜<span style="color:#1ab370;margin-left:20px" v-if="beauty" @click.stop.prevent="openSetting">设置美颜效果</span></el-checkbox>
                </div> -->
                <div class="prejoin-button">
                  <el-button id="join_handle_btn_headless" type="primary" size="small" :disabled="!(conferenceNo && userName)" @click="handleJoin">{{$t('login.login')}}</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer v-if="!joinParamData || joinParamData && joinParamData.businessType != 10008"></Footer>
      </div>
    </div>


    <!-- 会议设置弹窗 -->
    <meetingSettings ref="settingEl" @isBeautyChange="isBeautyChange" :myCamera="myCamera"></meetingSettings>

    <!-- 修改密码 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="passwordDialog"
      @close="passwordDialogCloseHandle"
    >
      <div class="dialog-title" style="marginBottom: 15px;">
        <div class="text">
          {{ $t('login.pleasePws') }}
        </div>
      </div>

      <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm" @submit.native.prevent>
        <el-form-item prop="pwd">
          <el-input :placeholder="$t('login.meetNo')" clearable  @input="onInputPwd" v-model="passwordForm.pwd" @keyup.enter.native="passwordSubmit"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialog = false">
          {{ $t('login.cancel') }}
        </el-button>

        <el-button type="primary" @click="passwordSubmit">
          {{ $t('login.sure') }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 是否可以预约会议和个人会议提前进入 -->
    <el-dialog
      custom-class="advanceElDialog"
      :width = "$i18n.locale === 'en-US' ? '570px' : '420px'"
      :visible.sync="advanceDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="advanceDialogCloseHandle"
    >
      <div class="axiosTitle">{{ advanceErrorData.content }}</div>

      <div class="axiosItem" v-if="advanceErrorData.beginTime && advanceErrorData.type === 1 && joinParamData && joinParamData.businessType != 10007">
        <div class="axiosItemTitle">{{ $t('login.startTime') }}</div>
        <div class="axiosItemValue">{{ advanceErrorData.beginTime }}</div>
      </div>

      <div class="axiosItem">
        <div class="axiosItemValue mutRowsTitle">{{ advanceErrorData.title }}</div>
      </div>
      <div class="axiosItem" v-if="joinParamData && ![10007,10008].includes(joinParamData.businessType)">
        <div class="axiosItemValue2">{{$t('login.ifMain')}}</div>
      </div>

      <span slot="footer" class="dialog-footer" style='display:inline-block'>
        <el-button @click="goLoginPage" v-if="joinParamData && ![10007,10008].includes(joinParamData.businessType)">{{$t('home.signIn')}}</el-button>
        <el-button type="primary" @click="advanceDialog = false">{{$t('login.leaveMeet')}}</el-button>
      </span>
    </el-dialog>

    <!-- 主持人正在开启另外的会议 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="waitHostDialog"
      @close="waitHostDialogCloseHandle"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">
          {{ waitHostErrorData.tips }}
        </div>
      </div>

      <div class="dialog-content">
        <div class="content-item">
          {{ $t('login.meetNumber') + waitHostErrorData.conferenceNo }}
        </div>
        <div class="content-item">
          {{ $t('login.meetTheme') + waitHostErrorData.title }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="waitHostDialog = false">
          {{ $t('login.leaveMeet')}}
        </el-button>
      </span>
    </el-dialog>

    <!-- 结束会议弹窗 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :visible.sync="endMeetElDialog"
    >

      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{ endMeetElDialogTitle }}</div>
      </div>

      <div class="dialog-content">
        <div class="content-item">
          {{ $t('login.meetNumber') + forceEndMeetInfo.conferenceNo }}
        </div>
        <div class="content-item">
          {{ $t('login.meetTheme') + forceEndMeetInfo.title }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="forceEndMeetHandle">{{$t('login.end')}}</el-button>
        <el-button @click="endMeetElDialog = false">{{$t('login.noEnd')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import Back from "@/components/back/Back";
import joinErrorMixins from '@/mixins/joinError.mixins'
import { strToBoolean,strMiddleEllipsis } from '@/utils/str'
import { THROTLE_TIME } from "@/constant/index";
import { getLocalConferenceNo, setLocalConferenceNo, getlocalUserId, setlocalUserId,clearUhfList, getDisplayName, setDisplayName, getToken, setMeetInfo, setMeetNumbers , getMeetNumbers,setMopanMeetToken } from '@/utils/auth'
import { getLocalDeviceInfo } from "@/utils/device"
import { joinConference,wordsFilter ,landingToken,landingConfirm } from '@/api/meet'
import MeetingSettings from "@/views/meeting/Meeting.Settings.vue";
import { loganLog } from '../../YBMeetSDK/utils/log';
import { getBrowser,getUrlDate,getDate } from "@/utils/tool"
// import {  checkDevicePermission } from "@/utils/device"
import CryptoJS from "crypto-js";

export default {
  mixins: [joinErrorMixins],
  components: {
    Header,
    Footer,
    Back,
    MeetingSettings,
  },
  data() {
    return {
      video: "",
      myCheckbox: false,
      loudspeaker: true,
      myCamera: false,
      isShow: false,
      isShowVideo: false,
      isShowBackLink: true,
      conferenceNo: '',
      userName: '',
      beauty:false,
      uhfList:[],
      joinParamData:'',
      bgClassType:0,
      titleInfo: '',
      timeinfo: '',
    };
  },
  async created() {
    this.initEnterHandle()
    //设置休眠不断
    this.noSleep = new this.$noSleep();
    this.bindNoSleep()

    this.updataUhfList()

    let paramsData = getUrlDate(window.location.href)
    
    if(paramsData && paramsData.param){ // 信息采集业务
      const secretKey = '5szizEl7eHBryFsWub30APkG6ujhVkVYktRxq8AtQH9Scf9xD7uC5VY27sR6wgu9';
      //加密规则用AES加密算法，最后的param需要encodeURIComponent一下。secretKey:"5szizEl7eHBryFsWub30APkG6ujhVkVYktRxq8AtQH9Scf9xD7uC5VY27sR6wgu9"，需要传过来的参数 businessType、accessToken、name、hospital、conferenceNo，下面是示例
      // const data = JSON.stringify({
      //   businessType:10007,//业务类型 写死
      //   accessToken:'xxxxx',
      //   name:'小虎', //参会人员名称
      //   hospital:'朝阳医院',//医院名称
      //   conferenceNo:'426398732'//会议号
      //   turnLocalMic:true
      // });
      // let param = encodeURIComponent(CryptoJS.AES.encrypt(data, secretKey).toString())
      try {
        let paramData =  CryptoJS.AES.decrypt(decodeURIComponent(paramsData.param), secretKey).toString(CryptoJS.enc.Utf8);
          paramData = JSON.parse(paramData)
        if(paramData && paramData.businessType){
          this.joinParamData = paramData
          console.error(111111133,paramData)
        }
      } catch (error) {
        // this.$totast('join参数错误')
        loganLog(`join参数错误--${paramsData.param}--error:${error}`)
        // this.$router.push({ name: "login" });
        // console.error(11111113322,error)
      }
    }else if(paramsData && paramsData.token){// 代表台业务
      
      this.joinParamData = {
        businessType : 10008,
      }
      setMopanMeetToken(paramsData.token)

      // 背景图
      this.bgClassType = 1
      if(paramsData && paramsData.domain){
        this.joinParamData.domain = paramsData.domain
        if(paramsData.domain.match(/omnitest|ryzd/)){
          this.bgClassType = 2
        }
      }
      try {
        const resData = await landingToken({
          token: paramsData.token
        })

        if (resData.success) {
          //token有效时，返回的redicturl是当前工作台的邀请链接，在会中展示
          this.titleInfo = resData.info.topic
          let startTime = new Date(resData.info.planStartTime)
          let endTime = new Date(resData.info.planEndTime)
          let ymd = getDate(startTime)
          let _endYmd = getDate(endTime)
          this.timeinfo = `${ymd} ${startTime.toTimeString().substr(0, 5)}~${getDate(endTime)!=getDate(startTime) ? _endYmd + ' ' : ''}${ endTime.toTimeString().substr(0,5)}`
        } else {
          loganLog(`代表台confirm接口非success--res:${JSON.stringify(resData)}`)
          this.$confirm('您已离开会议', '提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            closeOnClickModal: false, //是否可通过点击遮罩关闭
            closeOnPressEscape: false, //是否可通过按下 ESC 键关闭 
            showClose: false, //是否显示右上角关闭按钮
            center: true,
          }).then(() => {
            if(resData.redirectUrl){
              window.location.href = resData.redirectUrl;
            }
          })
        }
        

        const confirmResData = await landingConfirm({
          token: paramsData.token
        })

        if (confirmResData.success) {
          this.joinParamData.conferenceNo = confirmResData.info.code
          this.joinParamData.userName = confirmResData.info.username
          this.joinParamData.userId = confirmResData.info.userId
          this.joinParamData.appId = confirmResData.info.appIdList

          if(confirmResData.info.requirePassword && confirmResData.info.password){
            this.joinParamData.password = confirmResData.info.password
          }

        } else {
          loganLog(`代表台confirm接口非success--res:${JSON.stringify(confirmResData)}`)
          if(confirmResData.error && confirmResData.error.includes('当前在线人数')){
            this.$confirm(confirmResData.error, {
            confirmButtonText: '确定',
            showCancelButton: false,
            closeOnClickModal: false, //是否可通过点击遮罩关闭
            closeOnPressEscape: false, //是否可通过按下 ESC 键关闭 
            showClose: false, //是否显示右上角关闭按钮
            center: true,
          })}else{
            window.location.href = confirmResData.redirectUrl
          }
        }
        
      } catch (error) {
        loganLog(`代表台confirm接口失败--error:${ this.browser}`)
        this.joinErrorHandle(error)
      }
    }

    let that = this;
    let conferenceNo = that.$route.query.conferenceNo
    this.userName = this.joinParamData && this.joinParamData.businessType == 10007 ? this.joinParamData.name : getDisplayName() || this.$store.state.user.realName

    if(this.joinParamData && [10008].includes(this.joinParamData.businessType)){
      this.userName = this.joinParamData.userName
    }
    if(this.joinParamData && [10007,10008].includes(this.joinParamData.businessType)){
      conferenceNo = this.joinParamData.conferenceNo
    }

    if (conferenceNo) { // 邀请函，url上附加conferenceNo
      that.conferenceNo = conferenceNo;
    } else if(![10007,10008].includes(this.joinParamData.businessType)){ // 查看本地存储
      that.conferenceNo = getLocalConferenceNo() || ''
    }

    //从上次操作记忆中加载勾选状态
    let isUseHuaTong = that.$loadLocal("isUseHuaTong")

    // console.error(11111222,this.joinParamData.turnLocalMic)
    if(this.joinParamData && this.joinParamData.turnLocalMic == 1){
      that.myCheckbox = true
    }else if(this.joinParamData && this.joinParamData.turnLocalMic == 0){
      that.myCheckbox = false
    }else{
      that.myCheckbox = strToBoolean(isUseHuaTong, false)
    }


    let isUseShiPin = that.$loadLocal("isUseShiPin")
    that.myCamera = strToBoolean(isUseShiPin, false)


    this.browser = getBrowser()
    // if (this.browser.chrome &&  this.browser.isMac || ( this.browser.safari &&  this.browser.safari >= 15)){
    if (!(this.browser.safari && this.browser.safari < 15) && this._gpuLevel == 'high'){
      let isBeauty = that.$loadLocal("isBeauty")
      that.beauty = strToBoolean(isBeauty, false)
    }else{
      that.beauty = false
      loganLog(`初始化不支持美颜--safari:${ this.browser.safari}-chrome:${ this.browser.chrome}-isMac:${ this.browser.isMac}-_gpuLevel:${ this._gpuLevel}`)
    }

    setTimeout(() => {
      that.handleDevice()
    }, 1000);
    let isUseYangShengQi = that.$loadLocal("isUseYangShengQi")
    that.loudspeaker = strToBoolean(isUseYangShengQi, true)
  },
  beforeDestroy() {
    this.removeEnterHandle()
    this.cancalCloseVideo()
  },
  beforeRouteLeave(to, form, next) {
    this.removeEnterHandle()
    this.cancalCloseVideo()
    console.log('beforeRouteLeave join')
    // setTimeout(() => {
      next()
    // }, 1000);
  },
  
  computed: {
    _gpuLevel() {
      return this.$store.state._gpuLevel
    },
    currentSelectedDevice() {
      return this.$store.state.device.currentCameraDevice
    },
  },
  methods: {
    strMiddleEllipsis,

    onInput(value){
      this.conferenceNo = value.replace(/[^\d]/g,'')
      if(this.conferenceNo.length > 19){
        this.conferenceNo = this.conferenceNo.substr(this.conferenceNo.length-20)
      }
    },

    onInputPwd(value){
      this.passwordForm.pwd = value.replace(/[^\d]/g,'')
    },
    updataUhfList(){
      this.uhfList = getMeetNumbers()
    },
    clearUhfList(){
      clearUhfList()
      this.updataUhfList()
    },
    selectBlur(item) {
      this.userName = item.name
    },
    keydownHandle(e) {
      if (e.keyCode == 13) {
        this.conferenceNo && this.userName && this.handleJoin()
      }
    },
    isBeautyChange(val){
      this.beauty = strToBoolean(val, false)
    },
    bindNoSleep() {
      let that = this
      document.addEventListener('click', function enableNoSleep() {
        document.removeEventListener('click', enableNoSleep, false);
        that.noSleep.enable();
      }, false);
    },

    initEnterHandle() {
      window.addEventListener('keydown', this.keydownHandle)
    },
    removeEnterHandle() {
      window.removeEventListener('keydown', this.keydownHandle)
    },

    // 点击返回按钮 判断页面跳转逻辑 (存在token 跳转index页面,不存在token跳转登录页面)
    handleBack() {
      if (!this.isShowBackLink) {
        return
      }
      if (getToken()) {
        this.$router.push({ name: "index" });
      } else {
        this.$router.push({ name: "login" });
      }
    },
    
    // 监听打开音频和视频
    changeDevice() {
      // if (this.myCamera && !this.video) {
      //   this.isShowBackLink = false
      // }
      this.handleDevice()
    },
    // 显示设置面板
    openSetting() {
      if(this._gpuLevel == 'high'){
        let index = 4
        this.$store.commit("isShowSettingPanel", true)
        this.$store.commit("settingPanelDefaultIndex", index)
      } else if(this._gpuLevel == 'middle') {
        this.$totast('设备性能不支持美颜功能')
      }else{
        this.$totast('设备性能不支持美颜和虚拟背景功能')
      }
    },
    async beautChecked(){
      // if (this.browser.chrome && this.browser.isMac || ( this.browser.safari &&  this.browser.safari >= 15)){
      if (!(this.browser.safari && this.browser.safari < 15)){
        if(this._gpuLevel == 'high'){
          await this.$saveLocal("isBeauty", this.beauty);
          this.$deviceControl.setBeautFn()
        } else if(this._gpuLevel == 'middle') {
          this.beauty =false
          this.$totast('设备性能不支持美颜功能')
        }else{
          this.beauty =false
          this.$totast('设备性能不支持美颜和虚拟背景功能')
        }
      }else{
        this.beauty =false
        if(this.browser.safari && this.browser.isMac){
          this.$totast('浏览器版本暂不支持此功能，请升级至15.0版本以上使用')
        }else{
          this.$totast('美颜功能开发中，敬请期待')
        }
        loganLog(`初始化不支持美颜--safari:${this.browser.safari}-chrome:${ this.browser.chrome}-isMac:${ this.browser.isMac}`)
      }
    },
    handleDevice: throttle(async function() {
      // if (!this.myCamera) {
      //   this.cancalCloseVideo();
      // }
      // if (this.myCamera && !this.video) {
      //   this.handleCamera();
      // }

      let video = document.getElementById("joinVideo");
      if (this.myCamera && !this.vide) {
        this.isShow = true
        this.isShowVideo = true;
        this.$deviceControl.startLocalPreviewOnly(this.currentSelectedDevice.deviceId,video,(type)=>{
          if(type == 'success'){
            this.$store.commit('isShowJoinVidewView', true)
          }else{
            this.myCamera = false
            this.isShow = false
            this.isShowVideo = false;
            this.$totast('打开摄像头失败，无法获取摄像头权限')
          }
        })
      }
      if (!this.myCamera){
        this.$deviceControl.stopLocalPreviewOnly(video,'joinView stop');
        this.isShow = false
        this.isShowVideo = false;
        this.$store.commit('isShowJoinVidewView', false)
      }

    }, THROTLE_TIME),

    // 打开摄像头
    handleCamera() {
      this.video = '';
      let video = document.getElementById("joinVideo");
      let deviceId = this.getCurrentDeviceId()
      let constraints = {
        video: this.myCamera
          ? { width: 1280, height: 720, deviceId: deviceId }
          : false,
      };
      const that = this
      let promise = navigator.mediaDevices.getUserMedia(constraints);
      promise
        .then((MediaStream) => {
          that.isShowVideo = true
          that.video = MediaStream.getTracks();
          video.srcObject = MediaStream;
          video.play();
          that.isShowBackLink = true
          
          setTimeout(function(){
            that.isShow = true
          }, 400)
        })
        .catch(function (PermissionDeniedError) {
          console.log(PermissionDeniedError);
          that.isShowBackLink = true
        });
    },
    getCurrentDeviceId() {
      const localDeviceData = getLocalDeviceInfo()
      const camDeviceList = this.$store.state.device.cameraDeviceList

      let queryDeviceId = localDeviceData.videoDeviceId
      if (!queryDeviceId && camDeviceList.length > 0) {
          queryDeviceId = camDeviceList[0].deviceId
      }
      return queryDeviceId
    },
    // 关闭麦克风和摄像头
    cancalCloseVideo() {
      if (this.video) {
        this.video.forEach((item) => {
          item.stop();
        });

        //释放资源
        let videoElement = document.getElementById("joinVideo");
        if (videoElement) {
          videoElement.src = ""
          videoElement.srcObject = null
        }

        this.isShow = false;
        this.isShowVideo = false;
        this.video = "";
      }
    },

    // 加入会议
    handleJoin: throttle(async function() {
      let data = {
        conferenceNo: this.conferenceNo.replace(/\s+/g, ""),
        userName: this.userName,
        closeConference: true,
      }

      try {
        await wordsFilter({ 
          bizType:'meeting',
          text:this.userName
        })
      } catch (error) {
        let _msg = [102,103,199001].includes(error.code) ? '名字内容存在敏感词，请检查后重试' : '名字内容' + error.msg
        this.$totast(_msg)
        loganLog(`加入会员名字错误--error:${JSON.stringify(error)}，test:${this.userName}`)
        return
      }
      
      if(data.conferenceNo.length > 10 || data.conferenceNo.length < 9){
        this.$totast('请输入正确会议号')
        return
      }

      if (this.passwordForm.pwd) {
        data.password = this.passwordForm.pwd
      }
      //获取未登录用户uid
      if(!getToken() && getlocalUserId()){
         data.userId = getlocalUserId()
      }
      
      if(this.joinParamData && this.joinParamData.businessType == 10007){
        data.userId = this.joinParamData.userId
        data.accessToken = this.joinParamData.accessToken
      }

      if(this.joinParamData && this.joinParamData.businessType == 10008){
        data.userId = this.joinParamData.userId
        data.appId = this.joinParamData.appId
        if (this.joinParamData.password) {
          data.password = this.joinParamData.password
        } 
      }
      

      // await checkDevicePermission()
      // .then(() => {
        this.joinMeeting(data)
      // })
      // .catch((error) => {
      //   this.$confirm(`系统检测未授权浏览器${error.type === "all" ? "麦克风/摄像头" : error.type === "video" ? "摄像头" : "麦克风"}权限，是否继续加入会议`, '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //   }).then(() => {
      //     this.joinMeeting(data)
      //   }).catch(() => {
                   
      //   });
      // })
      
    }, THROTLE_TIME,{ leading: true, trailing: false}),

    async joinMeeting(data) {
      
      try {
        const resData = await joinConference(data)
        const { conference,userid, roomid} = resData
        // 缓存未登录用户uid
        if(!getToken()){
          setlocalUserId(userid)
        }

        this.$saveLocal("isUseHuaTong", this.myCheckbox);
        this.$saveLocal("isUseYangShengQi", this.loudspeaker);
        this.$saveLocal("isUseShiPin", this.myCamera);
        this.$saveLocal("isBeauty", this.beauty);

        setLocalConferenceNo(data.conferenceNo)
        data.userName && setDisplayName(data.userName)

        // 入会失败的状态清理
        this.clearErrorState()


        // 存储token信息
        setMeetInfo(
          resData["X-Conference-Token"],
          resData["X-Channel-Token"]
        )
        setMeetNumbers(data.conferenceNo,resData.conference.title)
        this.updataUhfList()
        let queryData = {
          roomID: roomid, // 房间id
          conferenceNo: conference.conferenceNo, // 会议号
          conferenceId: conference.id, // 会议id
          userName: encodeURIComponent(this.userName)// 展示名
        }
        if(this.joinParamData && [10007,10008].includes(this.joinParamData.businessType)){
          queryData.businessType = this.joinParamData.businessType
          if (this.joinParamData.hospital){
            queryData.hospital = encodeURIComponent(this.joinParamData.hospital) || ''
          }
          if(this.joinParamData && this.joinParamData.domain){
            queryData.domain = this.joinParamData.domain
          }
        }
        this.$router.push({
          name: "meeting",
          params: { userID: this.joinParamData && [10007,10008].includes(this.joinParamData.businessType)? this.joinParamData.userId : userid },
          query: queryData 
        });
      } catch (error) {
        this.joinErrorHandle(error)
      }
      
    },
  }
}
</script>
<style scoped lang="css">
/deep/.commonElSelect{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
}
/deep/.commonElSelect .el-input--suffix .el-input__inner {
  border: none;
}

/deep/.commonElSelect .el-input--suffix{
  overflow: hidden !important;
  width: 36px;
  height: 36px;
  top: 2px;
  right: 8px;
}
 
/deep/.paddingleft .el-input__suffix .el-icon-circle-close:before{
  position: relative;
  right: 30px;
}
 .clearUhfList{
   padding: 20px  10px 0  10px;
   color: green;
   cursor: pointer;
 }
</style>

<style lang="scss">
@mixin axios_style($height,$size,$weight,$color,$margin){
  display: inline-block;
  height: $height;
  font-size: $size;
  font-weight:$weight ;
  font-family: PingFangSC-Medium, PingFang SC;
  color: $color;
  line-height: $height;
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  margin:$margin;
}
@mixin axios_item($weidth,$height,$size,$weight,$color,$margin){
  width: $weidth;
  height: $height;
  font-size:$size;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight:$weight;
  color:$color;
  line-height: 22px;
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  margin: $margin;
}
.join-container {
  color: rgb(184, 199, 224);
  background-color: #fff;
  background-size: cover;
  width: 100%;
  height: 100%;
  .commonElInput {
    width: 320px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .title.active {
    color: #ffffff;
    word-break: break-all;
  }

  .time_style {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    margin-top: 10px;
  }

  .line_style {
    width: 364px;
    height: 1px;
    background: #EEEEEE;
    margin-top: 16px;
  }

  .prejoin-input-area-container{
    position: relative;
    width: 320px;
  }
  #videoconference_page {
    // min-height: 100%;
    .premeeting-screen {
      align-items: stretch;
      //background: radial-gradient(50% 50% at 50% 50%, #2a3a4b 20.83%, #1e2a36 100%);
      display: flex;
      flex-direction: column;
      font-size: 1.3em;
      z-index: 2;
      .z-index {
        z-index: 1;
      }
      .back_active {
        ::v-deep .back_content {
          color: #ffffff;
          width: 60px;
          height: 24px;
          background: rgba(51, 51, 51, .4);
          border-radius: 2px;
          display: inline-block;
          text-align: center;
          line-height: 24px;
          &:hover {
            color: #1ab370;
          }
        }
      }
      
      #preview {
        height: 100%;
        position: absolute;
        width: 100%;

        video {
          height: 100%;
          object-fit: cover;
          position: absolute;
          width: 100%;
          display: inline-block;
          vertical-align: baseline;
        }

        .flipVideoX {
          transform: scale(-1, 1);
          -moz-transform: scale(-1, 1);
          -webkit-transform: scale(-1, 1);
          -o-transform: scale(-1, 1);
        }
      }

      .preview-overlay {
        //background-image: linear-gradient(transparent, #fff);
        z-index: 2;
      }

      .content {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        z-index: 2;
        margin: auto;
        .box{
          width: 412px;
          height: 474px;
          padding: 20px 20px 0 20px;
          box-sizing: border-box;
          background-color: #fff;
          align-items: center;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
        .box.gray {
           background: rgba(51, 51, 51, .4);
           border-radius: 8px;
        } 
        .prejoin-input-area {
          display: flex;
          flex-direction: column;
        }
        .title {
          font-size: 24px;
          font-weight: 500;
          color: #333333;
          line-height: 33px;
          margin-top: 20px;
          margin-bottom: 40px;
        }
        .title.active {
          color: #ffffff;
        }
        ::v-deep .input-box {
          font-size: 14px;
          width: 16px;
          height: 17px;
        }
        ::v-deep .input__word {
          font-size: 14px;
        }
        .prejoin-button {
          margin-top: 40px;
          button {
            width: 100%;
            height: 40px;
            font-size: 16px;
          }
        }
        .check-item {
          margin-top: 16px;
        }
      }
    }

    .premeeting-screen,
    .preview-overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.bg-default{
  background-image: url(../../assets/images/back.png);
}
.bg-omnitest{
  background-image: url(../../assets/images/backOmnitest.png);
  background-position: center;
  background-size: cover;
}

.commonSelectBox{
  top: 304px;
  width: 322px!important;
  left: 50% !important;
  margin-left: -161px;
  .el-select-dropdown__item{
    display: flex;
    justify-content: space-between;;
  }
}
.errorTip2 {
  margin-top: 10px;
  margin-left: 40px;
}
.new_footer{
  margin-top:20px;
}
.updatePwdTitle {
  @include axios_item(180px,28px,20px,500,#333333,4px 0 0 24px);
}
.axiosItemTitle {
  @include axios_item(120px,22px,13px,600,#999999,10px 0 0 24px);
  float: left;
}
.axiosItemValue {
  @include axios_item(200px,22px,16px,500,#666666,10px 0 0 24px);
  float: left;
}
.axiosTitle {
  @include axios_style(28px,20px,600,#333333,4px 0 0 24px);
}
.axiosItemValue2 {
  @include axios_style(22px,16px,500,#666666,10px 0 20px 24px);
  float: left;
}
.mutRowsTitle {
  width: 90%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: auto;
}
.axiosItem{
  height: 30px;
}
::v-deep .labelColor .el-checkbox__label {
  color: #ffffff;
}
::v-deep .el-checkbox,
::v-deep .no_video .el-checkbox__input.is-checked + .el-checkbox__label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

::v-deep .input{
  margin-right:0;
}

</style>
